.footer {
    background-color: black;
    color: white;
    width: 100%;
}

.footer-img {
    height: 100px;
    border-radius: 15px;

}

.footer-links {
    text-decoration: none;
    color: white;
    font-size: 18px;
}

.footer-links:hover {
    color: white;
}

hr { 
        display: block;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: auto;
        margin-right: auto;
        border-style: inset;
        border-width: 1px;
     } 
