.services-title {
    color: #009DAE;
    font-family: Poppins;
    font-weight: bolder;
    font-size: 48px;
}

.service {
    /* width: 500px;
    height: 500px; */
    background-color: whitesmoke;
    /* align-items: center;
    justify-content: center;
    position: relative; */
    /* margin-bottom: 50px; */
    cursor: pointer;
    
}

.service:hover .service-img{
    transform: scale(1.1);
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)); */
    /* background-color: black;
    opacity: 0.8; */
    /* background: rgba(2, 2, 2, 0.3) */
    filter: grayscale(60%);
}

.service:hover .cat {
    font-weight: bold;
}

.cat{
    position: absolute;
    /* top: 10px;
    left: 10px; */
    z-index: 999;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 22px;
}

.service-img {
    position: relative;
    width: 100%;
    height: 85%;
    border-radius: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    transition: all 1s ease;
}

.service-desc{
    top: 40%;
    width: 80%;
    text-align: center;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 999;
    color: white;
    opacity: 0;
    transition: all 1s ease;
    font-size: 18px;
}

.service:hover .service-desc {
    opacity: 1;
}

@media  screen and (max-width: 480px) {
    .services-title {
        font-size: 36px;
    }

    .manufacturing {
        margin-top: 40px;
    }

    .iot {
        margin-top: 40px;
    }
    .service-desc{
    top: 45%;
    width: 70%;
    text-align: center;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 999;
    color: white;
    opacity: 0;
    transition: all 1s ease;
    font-size: 12px;
    
    }
    
.service:hover .service-desc {
    opacity: 1;
}
}