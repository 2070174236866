.navbar {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0px 120px;
    justify-content: space-between;
    position: relative;
    background-color: white;
}

a { 
    text-decoration: none;
    color: black;
}

a:hover {
    text-decoration: none;
}

.list {
    align-items: center;
    margin-bottom: 0px;
    text-decoration: none;
}

.listItem {
    display: inline;
    margin-right: 30px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
}

.hamburger {
    display: none;
}

.menu{
    display: none;
}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 0 20px;
    }
    .list{
        display: none;
    }

    .hamburger{
        display: flex;
        flex-direction: column;
        width: 25px;
        height: 20px;
        cursor: pointer;
        justify-content: space-between;
    }

    .line {
        width: 100%;
        height: 3px;
        background-color: black;
    }

    .menu{
        position: fixed;
        height: calc(100vh - 50px);
        width: 50vw;
        background-color: white;
        top: 80px;
        margin: 0;
        padding: 0;
        font-weight: normal;
        color: black;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 999;
        transition: all 1s ease;
        text-decoration: none;
    }
}